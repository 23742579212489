
import axios from "axios";
import { gerar_token, somenteNumeros } from './DSi_basico'

var URL_DSI = sessionStorage['url']



//  export async function status_whats(){
 export async function status_whats(alertar, conectar, retornar_campo){


    

    let config_whats = JSON.parse(sessionStorage['config_whats'])
    console.log('config_whats:', config_whats);

    if (!config_whats){
      console.log('WhatsApp não configurado!')
      sessionStorage['zapzap_mostrar'] = false
      return false
    }
    else{
      sessionStorage['zapzap_mostrar'] = true
    }


    let ide      = sessionStorage['lg:IDE']
    let token    = parseInt(ide * 777888999)
    let api_url  = config_whats.API_LINK
    api_url      = api_url?.replaceAll('@token', token) //removo caracter especial
    api_url      = api_url?.replaceAll('send-messages','status') //removo caracter especial
    
    let api_qr   = api_url?.replaceAll('/send-messages','') //removo caracter especial

    // console.log('api_url:', api_url)



    let retorno = ''
    try {

        const data = {
          url:  api_url,
        }  
        // console.log('data:' , data);
        // const header =   {}
        const header =   { headers: {'Content-Type': 'application/json'}}//TEM QUE USAR O json_decode NO PHP que vai receber


        const res = await axios.post(URL_DSI+'scapeCors.php', data, header);

        retorno = JSON.parse(res.data);
        // console.log('retorno:', retorno);


        if (retorno.connected){
            if (alertar){
              alert('Módulo WhatsApp conectado com sucesso!')
            }
        }else{

            //nao esta conectado
            if (conectar){

              // pergunta se quer conectar
              if (confirm("Módulo WhatsApp não conectado! Deseja conectar?")){
                  await conectar_whats() 
              }
              else{
                return false
              }
            } 
        }

        // muda o session para ser lido pelas janelas
        sessionStorage['zapzap_conectado'] = retorno.connected


        if (retornar_campo){
          // retorna somente o campo especifica
          return retorno[retornar_campo]
        }
        else{
          // retorna o objeto inteiro
          return  retorno
        }
        

      // res = await axios.get(url, header) //ORIGINAL ZAPI
    } catch (err) {
        // Handle error
        // console.log('status_zap: ', err);
        // this.zap_message = err.message
        // o programa pode nao estar rodando la no servidor
        // this.zap_message = 'Atualizando Servidor, tente novamente mais tarde'
        console.log('ERRO:', err.message);
        sessionStorage['zapzap_conectado'] = false
        // return err.message
        // return {connected: false, error: `Atualizando Servidor, tente novamente mais tarde: ${err.message}`}

        if (alertar){
            alert('Atualizando o Servidor, tente novamente mais tarde')
        }


        if (retornar_campo){
          // retorna somente o campo especifica
          return false
        }
        else{
          // retorna o objeto inteiro
          return {connected: false, error: `Atualizando Servidor, tente novamente mais tarde: ${err.message}`}
        }
    }

 };


 export async function conectar_whats(){

  let config_whats = JSON.parse(sessionStorage['config_whats'])
  console.log('config_whats:', config_whats);

  if (!config_whats){
    console.log('WhatsApp não configurado!')
    sessionStorage['zapzap_mostrar'] = false

    return false
  }
  else{
    sessionStorage['zapzap_mostrar'] = true
  }


  let ide      = sessionStorage['lg:IDE']
  let token    = parseInt(ide * 777888999)

  let url
  url = config_whats.API_LINK
  url = url?.replaceAll('/send-messages','') //removo caracter especial
  url = url?.replaceAll('@token', token) //removo caracter especial

  // console.log('url:', url);
  
  window.open(url, '_blank');

};


 export async function send_whats_api(tipo, celular, texto, file){


    if (!celular){
        return false
    }
    celular =  await somenteNumeros(celular)


    let url = meu_url()+`clientes/envia_whats.php`

    let config_whats = JSON.parse(sessionStorage['config_whats'])
    // console.log('config_whats:', config_whats);

    let ide = sessionStorage['lg:IDE']
    let token = parseInt(ide * 777888999)
    let api_url = config_whats.API_LINK
    api_url       = api_url?.replaceAll('@token', token) //removo caracter especial
    

    let textoTratado = await encontrarEmojiNoTexto(texto);//pega emoji e transforma em texto para ser salvo no 45
    texto = textoTratado
    
    let ID_DSI = await gerar_token(ide)

    const data = {
                            
        acao            : 'ENVIA_SIMPLES',
        api_url         : api_url,
        tipo            : tipo,
        id_cliente      : ide, 
        empresa         : sessionStorage['lg:EMPRESA'], 
        id_dsi          : ID_DSI, 
        usuario         : sessionStorage['lg:USUARIO'], 
        celular         : celular, 
        msg             : texto, 
        file            : file 
        // optionList      : optionList, 
    }  

    // console.log(data);
    const header = { headers: {'Content-Type': 'application/x-www-form-urlencoded'}}//so funciona com isso daqui
    const res    = await axios.post(url, data, header);//envia 
    let retorno  = (res.data);
    // console.log('retorno:', retorno);
    return retorno

 }; 



 export async function send_whats_api_45(tipo, origem, empresa, celular, msg, dia, hora, file){

    celular = celular.replace(/\D/gim, '');//somente numeros
    let url = ''


    if ((!celular)||(!msg)){
       return 'exit-celular ou msg não definidos'
    }

    // console.log('URL_DSI::', URL_DSI);
    if (URL_DSI=='/api/'){
        url     = `http://localhost/vue_cfc/public/clientes/envia_whats.php`
    }
    else{
        url     = `https://dsi.app.br/f/c/clientes/envia_whats.php`
    }
    

    let api_url = "http://dsi.app.br:8051/35005004955/send-messages"
    let ID_DSI = await gerar_token(45)


    msg = await EmojiToText(msg)

    const data = {
                    
        acao            : 'ENVIA_SIMPLES',
        tipo            : tipo,
        api_url         : api_url,
        id_cliente      : '45', 
        empresa         : empresa, 
        id_dsi          : ID_DSI, 
        usuario         : origem, 
        celular         : celular, 
        msg             : msg,
        data            : dia,
        hora            : hora,
        file            : file 
    } 


    // console.log(data);
    const header = { headers: {'Content-Type': 'application/x-www-form-urlencoded'}}//so funciona com isso daqui
    const res    = await axios.post(url, data, header);//envia 
    let retorno  = (res.data);

    // console.log('envia_simples_whats:', retorno);
    // let retorno_obj = JSON.parse(retorno);

    return retorno

 };


 export async function enviar_email_45(para, assunto, texto){
    // console.log('enviar_email:');

    let link = "https://dsi.app.br/f/c/email.php"

    const data = {
              
              para:      para,
              assunto:   assunto, 
              texto:     texto,
    }  

    // alert(link)
    // console.log(data);
    const header =   { headers: {'Content-Type': 'multipart/form-data'}}

    const res = await axios.post(link, data, header);
    // console.log('enviar_email_45:', res.data);
    return res.data

 };



  function meu_url(){
          
    let url = ''

    if (sessionStorage['url'] == '/api/'){
      url =  `http://localhost/vue_cfc/public/`
    }
    else{
      url =  `https://dsi.app.br/f/c/`
    }

    return url
 };


 export async function encontrarEmojiNoTexto(texto) {

  // console.log('texto:', texto);
  // Divida o texto em palavras usando espaços em branco como delimitadores
  const palavras = texto.split(/\s+/);

  let achou = '';
  for (let i = 0; i < palavras.length; i++) {
    // Verificar se a palavra contém sequências percent-encoded
      try {
        // Decodificar a sequência percent-encoded dentro da palavra
        achou = encodeURIComponent(palavras[i]);
        // console.log('achou:', achou);

        // se achou ele substitui no texto
        texto = texto.replaceAll(palavras[i] , achou );


      } catch (error) {
        // console.error(`Erro ao decodificar sequência percent-encoded: ${palavras[i]}`);
        achou = palavras[i];
      }
  }

  // console.log('palavras:', palavras);

  return texto
};




export async function EmojiToText(texto) {
  // Divide o texto em palavras usando espaços em branco como delimitadores
  const palavras = texto.split(/\s+/);

  palavras.forEach(palavra => {
      try {
          // Verifica se a palavra contém caracteres fora do padrão ASCII (possível emoji ou caractere especial)
          if (/[^\x20-\x7E]/.test(palavra)) {
              // Codifica a palavra usando percent-encoding
              const achou = encodeURIComponent(palavra);

              // Substitui a palavra original pela codificada no texto
              texto = texto.replace(palavra, achou);
          }
      } catch (error) {
          // Caso ocorra algum erro, ignora a palavra
      }
  });

  return texto;
};


// apagar essa funcao depios de 01/01/2025 ver se uso em algum lugar
export async function encontrarCaractereNoTexto(texto) {

  // console.log('texto:', texto);
  // Divida o texto em palavras usando espaços em branco como delimitadores
  const palavras = texto.split(/\s+/);

  let achou = '';
  for (let i = 0; i < palavras.length; i++) {
    // Verificar se a palavra contém sequências percent-encoded
    if (/%[0-9A-Fa-f]{2}/.test(palavras[i])) {
      try {
        // Decodificar a sequência percent-encoded dentro da palavra
        achou = decodeURIComponent(palavras[i]);
        // console.log('achou:', achou);

        // se achou ele substitui no texto
        texto = texto.replaceAll(palavras[i] , achou );


      } catch (error) {
        // console.error(`Erro ao decodificar sequência percent-encoded: ${palavras[i]}`);
        achou = palavras[i];
      }
    } else {
      // Se a palavra não contiver sequências percent-encoded, mantenha a palavra original
      achou = palavras[i];
    }
  }

  // console.log('palavras:', palavras);

  return texto
};
